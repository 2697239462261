import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"advantages"} />
		<Helmet>
			<title>
				Eclipse Events
			</title>
			<meta name={"description"} content={"Unutulmaz Deneyimler Yaratmak"} />
			<meta property={"og:title"} content={"Eclipse Events"} />
			<meta property={"og:description"} content={"Unutulmaz Deneyimler Yaratmak"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header1>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header1>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Hizmetlerimiz
			</Text>
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Olağanüstü etkinlik planlama ve yönetimi için başvuracağınız ajansınız Eclipse Events hakkında bilgi edinin.
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Hizmetlerimizi keşfedin, ekibimizle tanışın ve bir sonraki etkinliğinizi nasıl olağanüstü hale getirebileceğimizi görün.
			</Text>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ekipman Kiralama
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Yüksek Kaliteli Ekipman: Toplar, ağlar, koniler ve taktik tahtaları dahil her ihtiyacınızı kiralayın.
					<br />
					{" "}Maç Topları: Oyunlar ve turnuvalar için FIFA onaylı futbol topları.
					<br />
					{" "}Eğitim Ekipmanları: Verimli antrenmanlar için koniler, engeller ve daha fazlası.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-4.jpg?v=2024-08-01T09:19:19.996Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-4.jpg?v=2024-08-01T09%3A19%3A19.996Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-4.jpg?v=2024-08-01T09%3A19%3A19.996Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-4.jpg?v=2024-08-01T09%3A19%3A19.996Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-4.jpg?v=2024-08-01T09%3A19%3A19.996Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-4.jpg?v=2024-08-01T09%3A19%3A19.996Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-4.jpg?v=2024-08-01T09%3A19%3A19.996Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-4.jpg?v=2024-08-01T09%3A19%3A19.996Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09:19:19.852Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09%3A19%3A19.852Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09%3A19%3A19.852Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09%3A19%3A19.852Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09%3A19%3A19.852Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09%3A19%3A19.852Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09%3A19%3A19.852Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/2-5.jpg?v=2024-08-01T09%3A19%3A19.852Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Etkinlik Yönetimi
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Turnuva Organizasyonu: Futbol turnuvalarınızı profesyonel gözetim altında yürütmemize ve yönetmemize izin verin.
					<br />
					{" "}Etkinlik Planlama: Planlama, hakemlik ve puanlamayı içeren kapsamlı etkinlik yönetimi.
					<br />
					{" "}Catering Seçenekleri: Etkinlikler sırasında yiyecek ve içecek sağlamak için yerel satıcılarla iş birliği yapın.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/66ab51b8e5d277001896bb07/images/1-4.jpg?v=2024-08-01T09:19:19.865Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/1-4.jpg?v=2024-08-01T09%3A19%3A19.865Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/1-4.jpg?v=2024-08-01T09%3A19%3A19.865Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/1-4.jpg?v=2024-08-01T09%3A19%3A19.865Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/1-4.jpg?v=2024-08-01T09%3A19%3A19.865Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/1-4.jpg?v=2024-08-01T09%3A19%3A19.865Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/1-4.jpg?v=2024-08-01T09%3A19%3A19.865Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ab51b8e5d277001896bb07/images/1-4.jpg?v=2024-08-01T09%3A19%3A19.865Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Daha Fazla Neden Eclipse Events'i Seçmelisiniz
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Hizmetlerimiz, lojistikle ilgilenirken oyuna odaklanabilmenizi sağlamak için esneklik ve rahatlık sunar. Profesyonel maçlardan topluluk etkinliklerine kadar Eclipse Events, her türlü etkinliği düzenlemek ve geliştirmek için donatılmıştır. Daha fazla bilgi edinmek için ekibimizle iletişime geçerek veya bizi ziyaret ederek sunduğumuz hizmetlerin tamamını keşfedin.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Fırsatları Keşfedin!
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Ve bu sadece başlangıç. Eclipse Events'de göze çarpmaktan çok daha fazlası var. Daha fazlasını keşfetmek için bizimle iletişime geçin veya şahsen ziyaret edin. Bir sonraki etkinliğinizi büyük bir başarıya dönüştürmemize izin verin!
			</Text>
		</Section>
		<Components.Contact />
		<Components.NewFooter>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="text2" />
			<Override slot="link1" />
			<Box min-width="100px" min-height="auto" color="--light" align-self="center">
				<Link
					href="/sartlar-ve-kosullar"
					color="--light"
					margin="0px 1rem 0px 0px"
					text-decoration-line="underline"
					font="--headline5"
				>
					Şartlar ve Koşullar
				</Link>
				<Link href="/gizlilik-politikasi" color="--light" text-decoration-line="underline" font="--headline5">
					Gizlilik Politikası
				</Link>
			</Box>
		</Components.NewFooter>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});